// Set variables for Edify Colours
$dark-blue: #141b4d;
$light-blue: #2cccd3;
$text-black: #191919;
$text-grey: #212121;
$text-white: #ffffff;
$warm-grey: #f1eeeb;

// Overwrite bootstrap variables
$dark: $dark-blue;
$body-color: $text-black;

$navbar-padding-y: 1rem;
$navbar-padding-x: 0;
$navbar-dark-color: $light-blue;
$navbar-nav-link-padding-x: 1rem;

$blue: $dark-blue;

@import "~bootstrap/scss/bootstrap";
@import "../components/Account/CreateAccount.scss";

@font-face {
  font-family: "Noto Sans";
  src: url("../assets/fonts/Noto Sans Normal.woff2") format("woff2"),
    url("../assets/fonts/Noto Sans Normal.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6,
.navbar-brand {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
}

.navbar-brand {
  font-size: 1.5rem;
  &:hover {
    color: #f1eeeb;
  }
}

form {
  label {
    font-weight: 600;
  }
}

.fp_loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fp_loading_background {
  @extend .fp_loading;
  background-color: $dark-blue;
}

.loader {
  border: 4px solid white;
  border-top: 4px solid $light-blue;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#homeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.aSideContainer {
  width: 300px;
  background: darkblue;
}

#contentContainer {
  width: 100%;
  background: $warm-grey;
  height: 100vh;
  overflow-y: scroll;
}

#header {
  flex-direction: row;
  justify-content: flex-end;
  background: white;
  border-bottom: 1px solid #727694;
  padding-left: 40px;
  padding-right: 40px;
}

#footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 81px;

  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);

  flex: none;
  order: 2;
  flex-grow: 0;

  .footer-div {
    display: flex;
    position: absolute;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 12px;
    width: 100%;
    height: 80px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
}

.button-div-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.button-div-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 12px;
  flex: none;
  order: 1;
  flex-grow: 1;
}

#content {
  background-color: white;
  display: flex;
  flex-direction: column;
}

.sidebar {
  left: 0px;
  top: 0px;
  width: 300px;
  height: 100%;
  z-index: 11;
  border-right: 1px solid #f6f6f6;
  //background: #141B4D;
}

.logo_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 10px 20px 24px;
  gap: 10px;
}

.btn-primary,
.btn {
  padding: 8px 20px;
  border-radius: 50px;
  color: $light-blue;
  border: none;
  font-size: 16px;
  width: fit-content;
  min-width: 150px;
}

.btn-input {
  background-color: #141b4d;
  border-radius: 50px;
  padding: 0%;
  color: $light-blue;
  border: none;
  font-size: 16px;
  width: fit-content;
  min-width: 150px;
  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    text-decoration: none;
  }
}

.btn-secondary {
  box-shadow: inset 0 0 0 3px #141b4d, 0 0 2px rgba(0, 0, 0, 0);
  background-color: #ffffff;
  color: #141b4d;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 16px;
  width: fit-content;
  min-width: 150px;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    background-color: #141b4d;
    text-decoration: none;
  }
}

.btn-link {
  padding: 0;
  box-shadow: none;
  background-color: transparent;
  color: #141b4d;
  font-size: 16px;
  width: fit-content;
  min-width: 0px;

  &:focus,
  &:active,
  &:hover {
    box-shadow: none;
    border: none;
    color: #2cccd3;
    border-color: transparent;
    background-color: transparent;
    text-decoration: none;
    outline: none;
  }
}

.btn-tag {
  background-color: #f1eeeb;
  color: #4d4d4d;
  padding-left: 8px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 8px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 700;
  width: fit-content;
  min-width: 0px;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    color: #ffffff;
    background-color: #141b4d;
    text-decoration: none;
  }
}

.inline {
  padding: 8px 16px;
  width: 100px;
  font-size: 12px;
}

.navbar-nav {
  font-size: initial;
}

.sidebar__holder {
  margin-top: 5%;
}

a.sidebar-menu-nav-link {
  margin: 10px 20px;
  text-decoration: none;
}

a.sidebar-menu-nav-link:hover {
  margin: 10px 20px;
  color: #f1eeeb;
  text-decoration: none;
}

.divOrganisationHeader {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.divOrganisation {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.spanHeader {
  float: left;
}

.spanCreateButton {
  float: right;
}

.spanFloatLeft {
  float: left;
}

.btnComponent {
  width: 100%;
  padding: 8px 20px;
}

.highlighted {
  color: lightblue;
}

#btnCreateOrganisation {
  width: 20%;
}

.divCreateOrganisationFormButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 3%;
}

.toolbar-container {
  display: flex;
  justify-content: flex-end;
  margin: 8px 20px;
}

.dropdown-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 0px 8px 20px;
  align-items: center;

  .spanDropDownLabel {
    margin-right: 8px;
    margin-left: 8px;
    font-weight: bold;
    font-size: small;
  }

  .spanDropDownItem {
    min-width: 160px;
  }

  .spanDropDownItem > select {
    width: 100%;
  }
}
.card_container_div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.divOrganisationCard {
  background: white;
  border: 1px solid #727694;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.divOrganisationDetailInnerDiv {
  margin: 2%;
}

.divLicenceItem {
  border: 1px solid #727694;
  border-width: thin;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.divOrganisationItem {
  display: flex;
  flex-direction: column;
}

.organisation-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px;

  .org-thumbnail {
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    margin-right: 10px;
    background-color: #191919;
  }
}

.accounts-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;

  span.accounts-title {
    padding: 4px;
    min-width: 70px;

    font-family: "Noto Sans";
    font-style: normal;
    font-size: 16px;
    line-height: 21px;

    text-align: right;

    color: #4d4d4d;
  }

  .accounts-content {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;

    color: #191919;

    a {
      margin-left: 8px;
    }
  }
}

.tags-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span.tag-title {
    padding: 4px;
    min-width: 70px;

    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;

    text-align: right;

    /* Text Palette/Black */
    color: #191919;
  }

  div.tags {
    ul {
      margin: 0;
      padding: 0;
    }

    ul > li {
      display: inline;
    }

    a.tag {
      margin: 0px 4px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;

      text-align: center;
      text-decoration: none;

      background-color: #f1eeeb !important;
      color: #4d4d4d !important;

      border-radius: 30px;
    }
  }
}

.nav-tabs .nav-item .nav-link,
.nav-tabs .nav-link {
  color: black !important;
  background-color: #fff;
  border-radius: 0 !important;
  border: none;
  font-size: medium;
  font-weight: bold;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: $dark-blue !important;
  background-color: #fff;
  border-radius: 0 !important;
  border-bottom: solid $dark-blue;
  font-size: medium;
  font-weight: bolder;
}

.nav-tabs {
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: $text-black 1px;
}

.nav-tabs .nav-link:hover {
  color: $dark-blue !important;
}

.tab-content-warm-grey {
  background-color: $warm-grey;
}

.home {
  height: 1012px;
  width: 980px;
  left: 0px;
  top: 80px;
  border-radius: 0px;
  padding: 40px 0px 80px 0px;
}

.txtAccountDescription {
  height: 160px;
  width: 602px;
  left: 0px;
  top: 28px;
  border-radius: 4px;
  margin-bottom: 2%;
}

.txtCreateOrganisation {
  height: 40px;
  width: 602px;
  left: 0px;
  top: 0px;
  border-radius: 4px;
}

.divBottomMargin {
  margin-bottom: 3%;
}

.divTags {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  padding: 2px;
  width: 100%;
}

.tagsInput {
  @extend .input;
  height: 40px;
  order: 0;
  flex-grow: 2;
  margin-right: -58px;
}

.tagsButton {
  border: 1px solid #141b4d;
  background: #141b4d;
  color: white;
  margin: 4px;
  width: 50px;
  border-radius: 4px;
}

.spanTagItem {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.form-group {
  margin: 0px !important;
}

.form-message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  flex: none;
  order: 0;
  border-left-style: solid;
  border-left-width: thick;
}

.form-message-failure {
  border-left-color: #dc3545;
  background: #fce8e9;
}

.form-message-success {
  border-left-color: #146c43;
  background: #eaf3e7;
}

.message-component-icon {
  width: 30px;
}

.message-component-title {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #191919;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.message-component-body {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4d4d4d;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: initial;
}

td,
th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.tdRightAligned {
  text-align: right;
}

.divOrganisationGeneralInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.divOrganisationDetailsTags {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}

.divOrganisationDetailsTagItem {
  padding: 0px;
  height: 32px;
  order: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 1 auto;
  min-width: 0;
  align-items: center;
  border-radius: 16px;
  color: white;
  width: auto;
  min-width: 90px;
}

.tagDescription {
  margin-left: 10px;
  margin-right: 10px;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
}

.btnWhiteComponent {
  width: 10%;
  background-color: white;
  border: solid thin #141b4d;
  margin-bottom: 1%;
  color: #141b4d;
}

.divOrganisationDetailName {
  margin-bottom: 10px;
}

.divOrganisationDetailSuspend {
  margin-right: 1%;
}

.divOrganisationDetailsEditTags {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.linkButton {
  display: inline;
  padding: 0;
  border: 0;
  color: #141b4d;
  background-color: transparent;
  text-decoration: underline;
  font-size: initial;
}

.licenceItemMargin {
  margin: 1%;
}

.successButtonsLayout {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}

.successButtonsLayout button {
  border-radius: 16px;
  height: 35px;
  border: solid thin #141b4d;
  font-size: initial;
}

.successButtonsAddAccount {
  background-color: #141b4d;
  color: white;
}

.successButtonAddOrganisation {
  background-color: white;
  color: #141b4d;
}

.successPageComponent {
  padding: 10px 40px;
  background-color: #f1eeeb;
}
