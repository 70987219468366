$form-label-font-size: 1.5rem;
$form-text-font-size: 1.25rem;
$input-font-size-sm: 1.5rem;
$input-font-size-lg: 1.5rem;
$form-select-font-size-lg: 1.5rem;
$input-font-size: 1.5rem;
$card-border-color: #727694;

@import "~bootstrap/scss/bootstrap";

.badge {
  border: 1px solid #595959;
  border-radius: 4px;
  text-align: left;
  font-size: 14px;
  max-height: 24px;
  min-height: 24px;
  max-width: 100px;
  min-width: 100px;
}

.badge-success {
  background-color: #eaf3e7;
  color: #519137;
}

.badge-secondary {
  background-color: #f2f2f2;
  color: #4d4d4d;
}

.badge-danger {
  background-color: #fce8e9;
  color: #d01120;
}

.label-success {
  color: #519137;
}

.label-danger {
  color: #d01120;
}

.label-secondary {
  color: #4d4d4d;
}

.form-label {
  display: contents;
}

.form-check-input {
  position: relative;
  min-height: 20px;
  max-height: 20px;
  min-width: 32px;
  max-width: 32px;

  &:checked {
    background-color: #2cccd3;
    border-color: #2cccd3;
  }
}

.form-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0px 80px;
  gap: 20px;
  background: #f1eeeb;
  min-height: 87vh;

  .form_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 20px;
    gap: 20px;
    height: 100%;
    flex: none;
    order: 0;
    flex-grow: 0;

    .warning {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      color: red;
      margin-bottom: 15px;
      font-size: 16px;
    }
  }

  .form_heading {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    text-align: left;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 602px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  .hint {
    color: #4d4d4d;
  }

  .input {
    display: block;
    align-self: stretch;
    padding: 8px 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: $dark-blue;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #727694;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      background: #e9effd;
      border-color: $dark-blue;
      outline: 0;
    }
  }

  .form_input {
    @extend .input;
    order: 1;
    flex: none;
    width: 600px;
    height: 40px;
    flex-grow: 0;
  }

  .form_text_area {
    @extend .form_input;
    height: 150px;
  }

  .invalid_form_input {
    display: block;
    width: 602px;
    height: 40px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    padding: 8px 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: $dark-blue;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid red;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      background: #e9effd;
      border-color: $dark-blue;
      outline: 0;
    }
  }

  .form_date_input {
    width: 200px;
    height: 40px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .form_select {
    width: 602px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    border: 1px solid #727694;
    border-radius: 4px;
  }

  .invalid_form_select {
    width: 602px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    border: 1px solid red;
    border-radius: 4px;
  }

  .form_date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 72px;
    height: 68px;
    flex: none;
    order: 0;
    flex-grow: 1;
  }

  .horizontal_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0px 0px;
    gap: 8px;
    width: 602px;
    height: 44px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .file_picker {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 262px;
    height: 24px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.img_preview {
  //border-radius: 50%;
  height: 100px;
  width: 100px;
  min-height: 100px;
  min-width: 100px;
  object-fit: cover;
  border: 2px solid #fff;
}

.filter_input {
  display: block;
  width: 300px;
  height: 32px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: $dark-blue;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #727694;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    background: #e9effd;
    border-color: $dark-blue;
    outline: 0;
  }
}

.custom_label {
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  margin-bottom: 0;
}

.grey {
  color: #4d4d4d;
}

.label_bold {
  @extend .custom_label;
  font-weight: 700;
}

.modal-label {
  font-size: 16px;
}

.font-sm {
  font-size: 12px;
}
.font-md {
  font-size: 14px;
}

.link {
  font-weight: 400;
  text-decoration-line: underline;
  color: #141b4d;
  background-color: transparent;
  border: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;

  &:hover,
  &:active {
    color: purple;
  }
}

.filter_select {
  width: 160px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border: 1px solid #727694;
  border-radius: 4px;
}

.horizontal_stretch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  height: 32px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.filter_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  flex-wrap: wrap;
}

.main_heading {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
}

.list_heading {
  width: auto;
  height: 40px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  text-align: left;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.flex_start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 370px;
  height: 32px;
  flex: none;
  flex-grow: 1;
  .flex_end_button {
    width: 32px;
    height: 32px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.flex_end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 256px;
  height: 40px;
  flex: none;
  order: 0;
  flex-grow: 1;

  .second {
    order: 1;
  }

  .third {
    order: 2;
  }
}

.list_content {
  padding: 60px;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
}

.column_flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  flex-grow: 1;
}

.row_flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  flex: none;
  flex-grow: 0;
}

.table_head {
  font-weight: 400;
  font-size: 16px;
}

.table_body {
  font-weight: 400;
  font-size: 14px;
}

.modal-div {
  max-height: 100px !important;
  overflow: auto;
}

.table_list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px;
  width: 100%;
  //height: 90%;
  flex: none;
  order: 0;
  flex-grow: 0;

  .table_heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 100%;
    height: 37px;
    background: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;

    .headers {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      width: 100%;
      height: 24px;
      flex: none;
      order: 0;
      flex-grow: 0;
      .header {
        //width: 161.5px;
        height: 24px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        flex: none;
        order: 0;
        flex-grow: 1;
      }
    }
  }
}

.img_letter {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  font-size: 25px;
  text-transform: uppercase;

  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  border-radius: 50%;
  min-height: 100px;
  min-width: 100px;
  width: 100px;
  height: 100px;
  border: 2px solid #141b4d;
}

.round_icon {
  width: 32px;
  height: 32px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  /* Interaction Palette/Royal Blue */

  border: 2px solid #141b4d;
  border-radius: 16px;
}

.tab_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 40px 60px;
  gap: 24px;
  width: 100%;
  height: 100%;
  background: #f1eeeb;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.btn-text {
  width: 49px;
  height: 24px;
  border-width: 0px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #191919;
  flex: none;
  order: 1;
  flex-grow: 0;
}

button:focus {
  outline: none;
}

.btn-icon {
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  padding: 0;

  &:hover {
    filter: invert(76%) sepia(80%) saturate(633%) hue-rotate(139deg)
      brightness(87%) contrast(88%);
  }

  &:active {
    filter: none;
  }
}

.audit-modal-body {
  width: 50%;
  max-width: none !important;
}

.audit-content {
  height: 30vw;
}

.audit-body {
  height: 80%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.tab-content {
  overflow: auto !important;
}
