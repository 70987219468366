.error-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #141b4d;
    padding: 10px 20px;

    p.title {
        color: #2cccd3;
        font-size: x-large;
        margin-top: 20px;
    }

    p.subtitle{
        color: white;
        font-size: larger;
    }
    
    span{
        color: white;
        font-size: large;
    }

    span.countdown {
        color: #2cccd3;
        font-size: large;
        font-weight: 700;
    }
}